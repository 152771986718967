import React from "react";

import Us1 from "../assets/us-1.webp";
export default function UsSection() {
    return (

        <div id="Us" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            <div class="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
                <div>
                    <img data-aos="zoom-out-right"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="rounded-xl " src={Us1} alt="Sobre nosotros" />
                </div>


                <div class="mt-5 sm:mt-10 lg:mt-0">
                    <div class="space-y-6 sm:space-y-8">

                        <div data-aos="zoom-out-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="space-y-2 md:space-y-4">
                            <h2 class="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200">
                                Sobre nosotros
                            </h2>
                            <p class="text-gray-500">
                                Somos una empresa constructora con más de 20 años de experiencia en el sector de la construcción, especializados en la construcción de viviendas, edificios, reformas integrales, rehabilitaciones, etc.
                            </p>
                        </div>

                        <ul role="list" class="space-y-2 sm:space-y-4">
                            <li data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="flex space-x-3">

                                <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1" />
                                    <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor" />
                                </svg>


                                <span class="text-sm sm:text-base text-gray-500">
                                    <span class="font-bold">Calidad Garantizada</span> con nosotros
                                </span>
                            </li>

                            <li data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="flex space-x-3">

                                <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1" />
                                    <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor" />
                                </svg>


                                <span class="text-sm sm:text-base text-gray-500">
                                    Los mejores <span class="font-bold">profesionales</span>
                                </span>
                            </li>

                            <li data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="flex space-x-3">

                                <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1" />
                                    <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor" />
                                </svg>


                                <span class="text-sm sm:text-base text-gray-500">
                                    Nos ajustamos a tu <span class="font-bold">presupuesto</span>
                                </span>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>

        </div>
    );
}
