import React from "react";
import Img from "../assets/hero-container.jpg";

function Hero() {
    const handleClickScroll = (event, id) => {
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
    return (
        <>
            <div id="home" className="container mx-auto px-6 py-8 md:py-16 flex flex-col justify-center items-center relative">
                <div data-aos="zoom-in"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="370"
              data-aos-delay="10" 
              className="bg-white bg-opacity-25 backdrop-filter backdrop-blur-[20px] bg-clip-padding backdrop-saturate-150 rounded-[20px] w-full max-w-6xl p-8">
                    <div className="flex flex-col md:flex-row items-center justify-between w-full">
                        <div className="flex flex-col items-center md:items-start w-full lg:w-1/2 space-y-8 px-4">
                            <h1 className="text-4xl md:text-6xl font-bold text-white text-center md:text-left">
                                Haz realidad la construcción de tus sueños
                            </h1>
                            <p className="text-white text-center md:text-left text-lg font-semibold">
                                Experiencia en construcción de casas, apartamentos, oficinas, locales comerciales, remodelaciones, ampliaciones, acabados, entre otros.
                            </p>
                            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                                <a href="/" className="bg-white bg-opacity-35 backdrop-filter backdrop-blur-[20px] bg-clip-padding backdrop-saturate-150 text-gray-800 px-6 py-3 rounded-[20px] font-semibold text-sm md:text-base w-full md:w-auto text-center">Construir ahora</a>
                                <a onClick={(e) => handleClickScroll(e, "projects")}
                    onMouseEnter={() => {
                      document.body.style.cursor = "pointer";
                    }}
                    onMouseLeave={() => {
                      document.body.style.cursor = "default";
                    }} href="/" className="text-white px-6 py-3 rounded-[20px] font-semibold text-sm md:text-base w-full md:w-auto text-center">Ver proyectos</a>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 drop-shadow-md">
                            <img src={Img} alt="hero" className="w-full h-full object-cover object-center rounded-[20px]" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
