import React, { useState } from "react";
import Modal from "./Modal";

export const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickScroll = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div class="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="Construccion CR"
          title="Construccion CR"
          class="inline-flex items-center"
        >
          <svg
            class="w-8 text-white"
            viewBox="0 0 24 24"
            strokeLinejoin="round"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            stroke="currentColor"
            fill="none"
          >
            <rect x="3" y="1" width="7" height="12" />
            <rect x="3" y="17" width="7" height="6" />
            <rect x="14" y="1" width="7" height="6" />
            <rect x="14" y="11" width="7" height="12" />
          </svg>
          <span class="ml-2 text-xl font-bold tracking-wide text-white uppercase">
            Construcción CR
          </span>
        </a>
        <ul class="flex items-center hidden space-x-8 lg:flex">
          <li>
            <a
            onClick={(e) => handleClickScroll(e, "home")}
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
              href="/"
              aria-label="Our product"
              title="Inicio"
              class="font-medium tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Inicio
            </a>
          </li>
          <li>
            <a
            onClick={(e) => handleClickScroll(e, "services")}
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
              href="/"
              aria-label="Servicios"
              title="Servicios"
              class="font-medium tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Servicios
            </a>
          </li>
          <li>
            <a
            onClick={(e) => handleClickScroll(e, "projects")}
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
              href="/"
              aria-label="Proyectos"
              title="Proyectos"
              class="font-medium tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Proyectos
            </a>
          </li>
          <li>
            <a
            onClick={(e) => handleClickScroll(e, "Us")}
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
              href="/"
              aria-label="Sobre nosotros"
              title="Sobre nosotros"
              class="font-medium tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Sobre nosotros
            </a>
          </li>
          <li>
            <a
              href="/"
              onClick={handleOpenModal}
              class="inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-gray-800 transition duration-200 rounded-[20px] shadow-md bg-white hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              aria-label="Contacto"
              title="Contacto"
            >
              Contacto
            </a>
          </li>
        </ul>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>

            <div class="relative flex flex-col w-full bg-white  rounded-[20px] shadow-lg outline-none focus:outline-none">
              <div class="max-w-xl mx-auto my-4 mt-10">
                <div class="text-center">
                  <h1 class="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
                    Contactanos
                  </h1>
                  <p class="mt-1 text-gray-600 dark:text-gray-400">
                    Estaremos encantados de atenderte
                  </p>
                </div>
              </div>

              <div class="mt-1 max-w-lg mx-auto">

                <div class="flex flex-col rounded-xl p-4 sm:p-6 lg:p-8">
                  <h2 class="mb-8 text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Rellena el formulario
                  </h2>

                  <form>
                    <div class="grid gap-4 lg:gap-6">

                      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                        <div>
                          <label for="hs-firstname-contacts-1" class="block text-sm text-gray-700 font-medium dark:text-white">Nombre</label>
                          <input type="text" name="hs-firstname-contacts-1" id="hs-firstname-contacts-1" class="py-3 px-4 block w-full border rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" />
                        </div>

                        <div>
                          <label for="hs-lastname-contacts-1" class="block text-sm text-gray-700 font-medium dark:text-white">Apellidos</label>
                          <input type="text" name="hs-lastname-contacts-1" id="hs-lastname-contacts-1" class="py-3 px-4 block w-full border rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" />
                        </div>
                      </div>

                      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                        <div>
                          <label for="hs-email-contacts-1" class="block text-sm text-gray-700 font-medium dark:text-white">Email</label>
                          <input type="email" name="hs-email-contacts-1" id="hs-email-contacts-1" autocomplete="email" class="py-3 px-4 block w-full border rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" />
                        </div>

                        <div>
                          <label for="hs-phone-number-1" class="block text-sm text-gray-700 font-medium dark:text-white">Numero de telefono</label>
                          <input type="text" name="hs-phone-number-1" id="hs-phone-number-1" class="py-3 px-4 block w-full border rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" />
                        </div>
                      </div>


                      <div>
                        <label for="hs-about-contacts-1" class="block text-sm text-gray-700 font-medium dark:text-white">Detalles</label>
                        <textarea id="hs-about-contacts-1" name="hs-about-contacts-1" rows="4" class="py-3 px-4 block w-full border rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"></textarea>
                      </div>
                    </div>


                    <div class="mt-6 grid">
                      <button type="submit" class="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800">Enviar</button>
                    </div>

                    <div class="mt-3 text-center">
                      <p class="text-sm text-gray-500">
                        Estaremos respondiendo en un plazo de 24 horas laborables.
                      </p>
                    </div>
                  </form>
                </div>

              </div>


            </div>

          </Modal>
        )}
        <div class="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-white" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div class="absolute top-0 left-0 w-full z-50">
              <div class="p-5 bg-white border rounded shadow-sm ">
                <div class="flex items-center justify-between mb-4 ">
                  <div>
                    <a
                      href="/"
                      aria-label="Construccion CR"
                      title="Construccion CR"
                      class="inline-flex items-center"
                    >
                      <svg
                        class="w-8 text-deep-purple-accent-400"
                        viewBox="0 0 24 24"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        stroke="currentColor"
                        fill="none"
                      >
                        <rect x="3" y="1" width="7" height="12" />
                        <rect x="3" y="17" width="7" height="6" />
                        <rect x="14" y="1" width="7" height="6" />
                        <rect x="14" y="11" width="7" height="12" />
                      </svg>
                      <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                        Construccion CR
                      </span>
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul class="space-y-4">
                    <li>
                      <a
                      onClick={(e) => handleClickScroll(e, "home")}
                      onMouseEnter={() => {
                        document.body.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.body.style.cursor = "default";
                      }}
                        href="/"
                        aria-label="Inicio"
                        title="Inicio"
                        class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a
                      onClick={(e) => handleClickScroll(e, "services")}
                      onMouseEnter={() => {
                        document.body.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.body.style.cursor = "default";
                      }}
                        href="/"
                        aria-label="Servicios"
                        title="Servicios"
                        class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Servicios
                      </a>
                    </li>
                    <li>
                      <a
                      onClick={(e) => handleClickScroll(e, "projects")}
                      onMouseEnter={() => {
                        document.body.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.body.style.cursor = "default";
                      }}
                        href="/"
                        aria-label="Proyectos"
                        title="Proyectos"
                        class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Proyectos
                      </a>
                    </li>
                    <li>
                      <a
                      onClick={(e) => handleClickScroll(e, "Us")}
                      onMouseEnter={() => {
                        document.body.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.body.style.cursor = "default";
                      }}
                        href="/"
                        aria-label="Nosotros"
                        title="Nosotros"
                        class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Nosotros
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={handleOpenModal}
                        class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-[20px] shadow-md bg-gray-400 bg-opacity-35 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                        aria-label="Contacto"
                        title="Contacto"
                      >
                        Contacto
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};