import React, { useState } from 'react';

import cta1 from '../assets/cta/cta-1.webp';
import cta2 from '../assets/cta/cta-2.webp';
import cta3 from '../assets/cta/cta-3.webp';

export default function CTA() {
  const [activeTab, setActiveTab] = useState('tabs-with-card-item-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getButtonClass = (tabId) => {
    return activeTab === tabId
      ? 'bg-white shadow-md hover:border-transparent text-left  p-4 md:p-5 rounded-[20px] dark:bg-slate-900 dark:hover:bg-gray-700 active'
      : 'text-left hover:bg-gray-200 p-4 md:p-5 rounded-[20px] dark:hover:bg-gray-700';
  };
  return (

    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div class="relative p-6 md:p-16">

        <div class="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
          <div data-aos="fade-up-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
            <h2 class="text-2xl text-gray-800 font-bold sm:text-3xl dark:text-gray-200">
              Construimos proyectos ajustados a tus necesidades y presupuesto
            </h2>


            <nav class="grid gap-4 mt-5 md:mt-10" aria-label="Tabs" role="tablist">
              <button
                type="button"
                className={getButtonClass('tabs-with-card-item-1')}
                id="tabs-with-card-item-1"
                data-hs-tab="#tabs-with-card-1"
                aria-controls="tabs-with-card-1"
                role="tab"
                onClick={() => handleTabClick('tabs-with-card-item-1')}
              >
                <span class="flex">
                  <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                    <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  </svg>
                  <span class="grow ml-6">
                    <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Planificación y estrategia en construcción</span>
                    <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Ofrecemos una planificación detallada y una estrategia eficaz para llevar a cabo proyectos de construcción de manera eficiente y efectiva.</span>
                  </span>
                </span>
              </button>

              <button
                type="button"
                className={getButtonClass('tabs-with-card-item-2')}
                id="tabs-with-card-item-2"
                data-hs-tab="#tabs-with-card-2"
                aria-controls="tabs-with-card-2"
                role="tab"
                onClick={() => handleTabClick('tabs-with-card-item-2')}
              >
                <span class="flex">
                  <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
                  </svg>
                  <span class="grow ml-6">
                    <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Eficiencia y satisfacción en construcción</span>
                    <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Ofrecemos un servicio integral y personalizado para ayudar a nuestros clientes a realizar sus proyectos de construcción de manera eficiente y con éxito, garantizando su satisfacción.</span>
                  </span>
                </span>
              </button>

              <button
                type="button"
                className={getButtonClass('tabs-with-card-item-3')}
                id="tabs-with-card-item-3"
                data-hs-tab="#tabs-with-card-3"
                aria-controls="tabs-with-card-3"
                role="tab"
                onClick={() => handleTabClick('tabs-with-card-item-3')}
              >
                <span class="flex">
                  <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z" />
                  </svg>
                  <span class="grow ml-6">
                    <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Construcción rápida y eficiente</span>
                    <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Nos esforzamos por llevar a cabo cada proyecto de construcción de manera rápida y eficiente, sin comprometer la calidad y la satisfacción de nuestros clientes.</span>
                  </span>
                </span>
              </button>
            </nav>

          </div>


          <div data-aos="fade-up-right"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="lg:col-span-6">
            <div class="relative">

              <div>
                <div id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1">
                  <img class="shadow-xl shadow-gray-200 rounded-[20px] dark:shadow-gray-900/[.2] w-[1000px] h-[450px] md:w-[1260px] md:h-[750px]" src={cta1} alt="Image Description" />
                </div>

                <div id="tabs-with-card-2" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-2">
                  <img class="shadow-xl shadow-gray-200 rounded-[20px] dark:shadow-gray-900/[.2] w-[1000px] h-[450px] md:w-[1260px] md:h-[750px]" src={cta2} alt="Image Description" />
                </div>

                <div id="tabs-with-card-3" class="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-3">
                  <img class="shadow-xl shadow-gray-200 rounded-[20px] dark:shadow-gray-900/[.2] w-[1000px] h-[450px] md:w-[1260px] md:h-[750px]" src={cta3} alt="Image Description" />
                </div>
              </div>

              <div class="hidden absolute top-0 right-0 translate-x-20 md:block lg:translate-x-20">
                <svg class="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" stroke-width="10" stroke-linecap="round" />
                  <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" stroke-width="10" stroke-linecap="round" />
                  <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" stroke-width="10" stroke-linecap="round" />
                </svg>
              </div>

            </div>
          </div>

        </div>



        <div data-aos="fade-up-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="absolute inset-0 grid grid-cols-12 w-full h-full">
          <div class="col-span-full lg:col-span-7 lg:col-start-6 bg-gray-100 w-full h-5/6 rounded-[20px] sm:h-3/4 lg:h-full dark:bg-white/[.075]"></div>
        </div>

      </div>
    </div>

  )
}
