import React, { useState } from "react";

import Services1 from "../assets/services/services-1.webp";
import Services2 from "../assets/services/services-2.webp";
import Services3 from "../assets/services/services-3.webp";
import Services4 from "../assets/services/services-4.webp";
import Services5 from "../assets/services/services-5.webp";
import Services6 from "../assets/services/services-6.webp";
import Services7 from "../assets/services/services-7.webp";
import Services8 from "../assets/services/services-8.webp";

export default function Services() {
    const [cardsToShow, setCardsToShow] = useState(4);

    const handleShowMore = () => {
        setCardsToShow(8);
    };

    const handleShowLess = () => {
        setCardsToShow(4);
    };

    const cards = [
        {
            title: "Construcción de viviendas",
            description:
                "Construcción de casas y edificios residenciales, desde la planificación hasta la finalización",
            imageUrl:
                Services1
        },
        {
            title: "Remodelaciones y renovaciones",
            description:
                "Incluye la mejora y actualización de edificios existentes, desde la reconfiguración de espacios hasta la instalación de nuevos acabados",
            imageUrl:
                Services2
        },
        {
            title: "Construcción de edificios comerciales",
            description:
                "Este servicio incluye la construcción de edificios de oficinas, tiendas y centros comerciales",
            imageUrl:
                Services3
        },
        {
            title: "Construcción de infraestructura",
            description:
                "Incluye la construcción de carreteras, puentes, presas, aeropuertos y otras estructuras importantes para el desarrollo de una comunidad",
            imageUrl:
                Services4
        },
        {
            title: "Servicios de ingeniería",
            description:
                "Ofrece servicios de diseño y planificación para proyectos de construcción, incluyendo análisis estructurales, diseño de sistemas eléctricos y mecánicos, y más",
            imageUrl:
                Services5
        },
        {
            title: "Servicios de gestión de proyectos",
            description:
                "Incluye la planificación, seguimiento y supervisión de proyectos de construcción, desde la concepción hasta la entrega final",
            imageUrl:
                Services6
        },
        {
            title: "Mantenimiento y reparación de edificios",
            description:
                "Realización de reparaciones y mantenimiento preventivo en edificios existentes para garantizar su buen funcionamiento y prolongar su vida útil",
            imageUrl:
                Services7
        },
        {
            title: "Servicios de consultoría",
            description:
                "Ofrecemos asesoramiento a propietarios de edificios, inversionistas y otros interesados en proyectos de construcción, incluyendo evaluaciones de costos, análisis de viabilidad y más",
            imageUrl:
                Services8
        },


    ];

    return (
        <div id="services" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
                    Algunos de nuestros servicios
                </h2>
                <p class="mt-1 text-gray-600 dark:text-gray-400">
                    Ofrecemos todos los servicios necesarios para que tu construcción sea
                    un éxito.
                </p>
            </div>

            <div data-aos="zoom-in-down"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
                    {cards.slice(0, cardsToShow).map((card) => (
                        <a class="group rounded-xl overflow-hidden" >
                            <div class="sm:flex">
                                <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                                    <img
                                        class="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 left-0 object-cover rounded-xl"
                                        src={card.imageUrl}
                                        alt={card.title}
                                    />
                                </div>

                                <div class="grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0">
                                    <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                                        {card.title}
                                    </h3>
                                    <p class="mt-3 text-gray-600 dark:text-gray-400">
                                        {card.description}
                                    </p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>

                {cardsToShow < cards.length ? (
                    <div className="text-center mt-6">
                        <button
                            onClick={handleShowMore}
                            className="bg-blue-500/90 hover:bg-blue-700 backdrop-filter backdrop-blur-[20px] bg-clip-padding backdrop-saturate-150 text-white px-10 py-3 rounded-[15px] font-semibold text-sm md:text-base w-full md:w-auto text-center"
                        >
                            Ver más
                        </button>
                    </div>
                ) : cardsToShow > 3 ? (
                    <div className="text-center mt-6">
                        <button
                            onClick={handleShowLess}
                            className="bg-blue-500/90 hover:bg-blue-700 backdrop-filter backdrop-blur-[20px] bg-clip-padding backdrop-saturate-150 text-white px-10 py-3 rounded-[15px] font-semibold text-sm md:text-base w-full md:w-auto text-center"
                        >
                            Ver menos
                        </button>
                    </div>
                ) : null}

            </div>
        </div>
    );
}

