import React, { useEffect, useState } from "react";
import Hero from "./components/hero";
import { Nav } from "./components/navbar";
import CTA from "./components/cta";
import Services from "./components/services";
import Projects from "./components/projects";
import UsSection from "./components/UsSection";
import Footer from "./components/footer";

import { CookieBanner } from "./components/CookieBanner";
import Img from "./assets/bg-img.jpg";

import "aos/dist/aos.css";
import AOS from "aos";

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  return (
    <>
      <div id="home" className="w-full bg-center bg-cover min-h-screen overflow-y-auto overflow-x-hidden" style={{ backgroundImage: `url(${Img})` }}>
        <Nav />
        <Hero />
      </div>
      <div className="overflow-y-auto overflow-x-hidden" >
        <CTA />
        <Services />
        <Projects />
        <UsSection />
        <Footer />
        <CookieBanner />
      </div>


    </>
  );
}

export default Home;