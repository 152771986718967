import React from "react";

const Modal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-3xl mx-auto my-6 ">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-[20px] shadow-lg outline-none focus:outline-none">
          {children}
          <button
            className="absolute top-0 right-0 z-50 flex items-center justify-center w-12 h-12 text-3xl font-semibold text-black transition-all duration-300 rounded-full outline-none focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2 focus:ring-offset-white dark:bg-red-600 dark:hover:bg-red-700 dark:focus:bg-red-700 dark:focus:ring-red-700 dark:focus:ring-offset-gray-800"
            onClick={closeModal}
          >
            ×
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
