import React from "react";

import Project1 from "../assets/projects/project-1.webp";
import Project2 from "../assets/projects/project-2.webp";
import Project3 from "../assets/projects/project-3.webp";

export default function Projects() {
    return (

        <div id="projects" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            <div data-aos="fade-right"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Algunos de nuestros proyectos</h2>
                <p class="mt-1 text-gray-600 dark:text-gray-400">¿Quieres ver la calidad de nuestros trabajos? Aquí te mostramos algunos de nuestros proyectos.</p>
            </div>

            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">

                <a data-aos="fade-right"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-[15px] p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4]" href="#">
                    <div class="aspect-w-16 aspect-h-11">
                        <img class="w-full object-cover rounded-[15px]" src={Project1} alt="Tiendas en centros comerciales" />
                    </div>
                    <div class="my-6">
                        <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
                            Tiendas en centros comerciales
                        </h3>
                        <p class="mt-5 text-gray-600 dark:text-gray-400">
                            Realizamos la construcción de varias tiendas en centros comerciales, desde el diseño hasta la entrega de llaves.
                        </p>
                    </div>
                    
                </a>

                <a data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-[15px] p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4]" href="#">
                    <div class="aspect-w-16 aspect-h-11">
                        <img class="w-full object-cover rounded-[15px]" src={Project2} alt="Edificios de oficinas" />
                    </div>
                    <div class="my-6">
                        <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
                            Edificios de oficinas
                        </h3>
                        <p class="mt-5 text-gray-600 dark:text-gray-400">
                            Trabajamos en la construcción de edificios de oficinas, adaptándonos a las necesidades de nuestros clientes.
                        </p>
                    </div>
                    
                </a>

                <a data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="370"
            data-aos-delay="10" class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-[15px] p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4]" href="#">
                    <div class="aspect-w-16 aspect-h-11">
                        <img class="w-full object-cover rounded-[15px]" src={Project3} alt="Reformas de viviendas" />
                    </div>
                    <div class="my-6">
                        <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
                            Reformas de viviendas
                        </h3>
                        <p class="mt-5 text-gray-600 dark:text-gray-400">
                            Realizamos reformas de viviendas, desde pequeñas reformas hasta reformas integrales.
                        </p>
                    </div>
                    
                </a>

            </div>

            <div class="mt-12 text-center">
                <a class="inline-flex justify-center items-center gap-x-2 text-center bg-white border hover:border-gray-300 text-sm md:text-base text-blue-500/90 hover:text-blue-700 font-medium hover:shadow-sm rounded-[15px] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition px-8 py-3 dark:bg-slate-900 dark:border-gray-700 dark:hover:border-gray-600 dark:text-blue-500 dark:hover:text-blue-400 dark:hover:shadow-slate-700/[.7] dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800" href="#">
                   Ver más proyectos
                    <svg class="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </a>
            </div>

        </div>


    )
}